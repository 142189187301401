/** Nazvy zvukov */
export enum AudioNames {

  commonSounds = 'common_sounds',
  loopedSounds = 'looped_sounds',
  randomSound = 'random_sound',
  commentatorSounds = 'commentators_audio',
  audienceNoise = 'audience_noise_var_02',
  audienceNoise1 = 'audience_noise_var_011',
  audienceNoise2 = 'audience_noise_var_012',
  audienceHype = 'audience_hype',
  audienceSad = 'audience_sad',
  audienceBad = 'audience_bad',
  audienceYay = 'audience_yay',
  audienceBells = 'bells_var02',
  audienceBells1 = 'bells_var021',
  countdownBeepLong = 'countdown_beep_long',
  countdownBeepShort = 'countdown_beep_short',
  skiingLoop = 'DH_skiing_loop',
  skiingCorner = 'DH_skiing_corner',
  skiingStart = 'BI_skiing_run_fastpace_all',
  skiingJump = 'DH_skiing_jump',
  skiingLanding = 'DH_skiing_landing',
  skiingOnIce = 'DH_skiing_onice',
  skiingLandingOnIce = 'DH_skiing_landing_onice',
  skiingBreak = 'DH_skiing_break',
  checkpointGood = 'DH_checkpoint_good',
  checkpointFail = 'DH_checkpoint_fail',
  crash = 'DH_crash',
  gateTouch = 'gatepass',
  wind = 'SJ_wind',
  voiceStart = 'voice_start_var02',
  voiceStart1 = 'voice_start_var021',
  voiceStart2 = 'voice_start_var022',
  voiceStart3 = 'voice_start_var023',
  voiceStart4 = 'voice_start_var024',

  commentAfterStart = 'after_start',
  commentAfterStart2 = 'after_start2',
  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentFinish1 = 'finish_1',
  commentFinish2 = 'finish_2',
  commentFinish3 = 'finish_3',
  commentFinish4 = 'finish_4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentMissedGate = 'missed_gate',
  commentMissedGate2 = 'missed_gate2',
  commentSplitTimesMinus = 'split_times_minus',
  commentSplitTimesMinus2 = 'split_times_minus2',
  commentSplitTimesMinus3 = 'split_times_minus3',
  commentSplitTimesMinus4 = 'split_times_minus4',
  commentSplitTimesPlus1 = 'split_times_plus_1',
  commentSplitTimesPlus11 = 'split_times_plus_12',
  commentSplitTimesPlus2 = 'split_times_plus_2',
  commentSplitTimesPlus21 = 'split_times_plus_22',
  commentSplitTimesPlus3 = 'split_times_plus_3',
  commentSplitTimesPlus31 = 'split_times_plus_32'

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  checkpoint = 'checkpoint',
  skiing = 'skiing',
  start = 'start',
  gates = 'gates',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
