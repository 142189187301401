import { defineStore } from 'pinia'

export interface DirectionsState {
  show: boolean,
  player: number,
  hill: number,
  movement: number,
  total: number
}

const initialState = (): DirectionsState => ({
  show: false,
  player: -9999,
  hill: -9999,
  movement: -9999,
  total: -9999
})

export const directionsState = defineStore('directionsState', {
  state: initialState,
})
