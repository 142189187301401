import { defineStore } from 'pinia'

export interface GateState {
  actualGate: number,
  successCounter: number,
  failedCounter: number
}

const initialState = (): GateState => ({
  actualGate: 0,
  successCounter: 0,
  failedCounter: 0
})

export const gatesState = defineStore('gatesState', {
  state: initialState,
})
