/**
 * Typy triggerov branok
 */
export enum TriggersGatesTypes {

  /*
   * left = 0,
   * right = 1,
   */
  afterFlags = 0,
  middle1 = 2,
  middle2 = 3,
  flagLeft = 4,
  flagRight = 5,
  animationTrigger = 6

}

/**
 * Typ pre vsetky triggre
 */
export type TriggersGatesAll = TriggersGatesTypes

/**
 * Info o medzicase
 */
export interface SplitInfo {

  splitIndex: number,
  gateIndex: number,
  time?: number,
  bestTime?: number,
  difference?: string

}