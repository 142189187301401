<template>
  <section class="positioner">
    <section
      v-if="inputsState.isVisible && actionButtonState.showMovementControl && isControlsTypeAutoTuck"
    >
      <movement-sides
        :disabled="inputsState.disabled"
        :left-style="{transformOrigin: 'bottom left'}"
        :right-style="{transformOrigin: 'bottom right'}"
        @position="changeMovement"
        @end-contact="endContact"
      />
    </section>
    <section
      v-if="inputsState.isVisible && !(isControlsTypeAutoTuck && actionButtonState.showMovementControl)"
      :key="String(pomKey)"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <movement-control
          v-if="actionButtonState.showMovementControl && !isControlsTypeAutoTuck"
          :style="`transform: scale(${scaleCoef})`"
          class="left"
          :disabled="inputsState.disabled"
        />
        <section v-show="!actionButtonState.showMovementControl" />
        <section
          :style="`transform: scale(${scaleCoef})`"
          class="relative right"
        >
          <action-button
            :is-scaled="false"
            :disabled="inputsState.disabled"
          />
          <start-efficiency-bar
            v-if="startPhaseState.showBar"
            :is-scaled="false"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          :style="`transform: scale(${scaleCoef})`"
          class="relative left"
        >
          <start-efficiency-bar
            v-if="startPhaseState.showBar"
            :is-scaled="false"
            :is-left="gameSettingsState.isLeft"
          />
          <action-button
            :is-scaled="false"
            :disabled="inputsState.disabled"
          />
        </section>
        <movement-control
          v-if="actionButtonState.showMovementControl && !isControlsTypeAutoTuck"
          :disabled="inputsState.disabled"
          :style="`transform: scale(${scaleCoef})`"
          class="right"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MovementControl from './MovementControl.vue'
import ActionButton from './ActionButton.vue'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import {
  gameSettingsState,
  MovementSides,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import {
  ControlsTypes,
  inputsManager,
  modes
} from '@powerplay/core-minigames'
import {
  actionButtonState,
  inputsState,
  movementState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  components: {
    MovementControl,
    StartEfficiencyBar,
    ActionButton,
    MovementSides
  },
  mixins: [WindowAspect],
  data() {

    return {
      pomKey: false,
      actionButtonState: actionButtonState(),
      inputsState: inputsState(),
      startPhaseState: startPhaseState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    isControlsTypeAutoTuck() {

      if (modes.isTutorial()) return true
      return gameSettingsState().controlsType === ControlsTypes.autoTuck ||
        Number(import.meta.env.VITE_APP_AUTOTUCK_FORCED) === 1

    }
  },
  watch: {
    actionButtonState: {
      handler() {

        this.$nextTick(() => {

          this.pomKey = true

        })

      }
    }
  },
  methods: {
    endContact() {

      movementState().positionX = 0

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      movementState().positionX = positionX

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.right {
  transform-origin: bottom right;
}
.left {
  transform-origin: bottom left;
}

</style>
