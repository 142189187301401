<template>
  <instructions-component
    class="pointer-events-auto midder"
    :header="$t('instructions')"
    :button-text="$t('continue')"
    :cards="getInstructions"
    show-button
    @button-click="closeInstructions"
  />
</template>

<script lang="ts">
import { Instructions } from '@powerplay/core-minigames-ui'
import {
  CorePhases,
  corePhasesManager,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import { pathAssets } from '@/globals/globalvariables'
import { defineComponent } from 'vue'
import { player } from '@/app/player'
import { blurState } from '@/stores'

export default defineComponent({
  name: 'InstructionComponent',
  components: {
    InstructionsComponent: Instructions
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close-instructions'],
  data() {

    return {
      pathImages: pathAssets
    }

  },
  computed: {
    getInstructions(): {imgSrc: string, header: string, text: string[]}[] {

      // document.getElementsByTagName('canvas')[0].classList.add('blur-class')
      blurState().isActive = true

      if (modes.isTrainingMode()) {

        return [
          {
            imgSrc: `${pathAssets}/ui/instructions/training-1.png?3`,
            header: this.$t('instructionsDisciplineTraining1Cell1Title'),
            text: [this.$t('instructionsDisciplineTraining1Cell1Text1')]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-2.png?3`,
            header: this.$t('instructionsDisciplineTraining1Cell2Title'),
            text: [this.$t('instructionsDisciplineTraining1Cell2Text1')]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-3-2.jpg`,
            header: this.$t('instructionsDisciplineTraining1Cell3Title'),
            text: [this.$t('instructionsDisciplineTraining1Cell3Text1')]
          }
        ]

      }

      const instructions: {imgSrc: string, header: string, text: string[]}[] = [
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-1.png?3`,
          header: this.$t('instructionsDiscipline1Cell1Title'),
          text: [this.$t('instructionsDiscipline1Cell1Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-2.png?3`,
          header: this.$t('instructionsDiscipline1Cell2Title'),
          text: [this.$t('instructionsDiscipline1Cell2Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-3.png?3`,
          header: this.$t('instructionsDiscipline1Cell3Title'),
          text: [this.$t('instructionsDiscipline1Cell3Text1')]
        }

      ]

      if (!modes.isTutorial() && !player.velocityManager.isAutoTuck()) {

        instructions.push({
          imgSrc: `${pathAssets}/ui/instructions/instructions-4.png?2`,
          header: this.$t('instructionsDiscipline1Cell4Title'),
          text: [this.$t('instructionsDiscipline1Cell4Text1')]
        })

      }

      return instructions

    }
  },
  methods: {
    closeInstructions(): void {

      // pokial mame prvotne instrukcie, tak ich zavrieme
      if (corePhasesManager.isActivePhaseByType(CorePhases.instructions)) {

        corePhasesManager.getActualPhase()?.finishPhase()

      }
      this.$emit('close-instructions')
      tutorialManager.blockInputsManually()

    }
  }

})

</script>

<style lang="less" scoped>
.instruciton-card-photo{
    width: 343px;
    height: 229px;
}
</style>
