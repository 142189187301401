import { ModeTypes } from '@powerplay/core-minigames'
import type { AppWSM2021Data } from '@powerplay/core-minigames'

/**
 * Konfig appky WSM 2021 pre vsetky obtiaznosti
 */
export const appWSM2021AllDifficultiesConfig: AppWSM2021Data = {
  [ModeTypes.wsm2021easy]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.42
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 65.61
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 67.6
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.85
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 67.22
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.07
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 68.35
          }
        ]
      }
    ],
    specialData: {
      split: [10, 21.52, 36.43, 53.7]
    }
  },
  [ModeTypes.wsm2021medium]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.42
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 61.61
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 63.6
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.85
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 63.22
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.07
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 64.35
          }
        ]
      }
    ],
    specialData: {
      split: [10, 20.52, 34.43, 50.7]
    }
  },
  [ModeTypes.wsm2021hard]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.42
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 57.61
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 59.6
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.85
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 59.22
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.07
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 60.35
          }
        ]
      }
    ],
    specialData: {
      split: [10, 19.52, 32.43, 47.7]
    }
  }
}
