import { THREE } from '@powerplay/core-minigames'
import type { BatchingObjectData } from '@powerplay/core-minigames'

/**
 * Konfig batching objektov
 */
export const batchingConfig: Map<string, BatchingObjectData> = new Map([
  ['Branka0R', {
    name: 'Branka0R',
    data: [
      {
        offset: new THREE.Vector3(459.74, 505.34, -778.89),
        rotation: new THREE.Vector3(0.00, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(478.04, 481.23, -851.99),
        rotation: new THREE.Vector3(-0.38, -0.14, -0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(487.39, 461.64, -926.43),
        rotation: new THREE.Vector3(-0.20, -0.12, -0.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(457.71, 435.72, -984.11),
        rotation: new THREE.Vector3(-0.29, 0.79, 0.19),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(441.34, 421.04, -1030.11),
        rotation: new THREE.Vector3(-0.22, -0.20, -0.19),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(474.10, 404.58, -1066.96),
        rotation: new THREE.Vector3(-0.65, -1.41, -0.41),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(538.43, 403.60, -1068.84),
        rotation: new THREE.Vector3(-0.21, -1.12, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(559.97, 393.94, -1107.68),
        rotation: new THREE.Vector3(-0.31, -0.09, 0.12),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(530.68, 376.44, -1149.64),
        rotation: new THREE.Vector3(-0.32, 0.41, 0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(519.56, 365.70, -1189.75),
        rotation: new THREE.Vector3(-0.30, -0.40, 0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(548.85, 344.12, -1218.39),
        rotation: new THREE.Vector3(-0.36, -0.28, -0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(541.54, 319.03, -1272.95),
        rotation: new THREE.Vector3(-0.32, -0.33, 0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(585.41, 308.81, -1286.35),
        rotation: new THREE.Vector3(-1.06, -1.33, -0.55),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(619.20, 301.23, -1297.68),
        rotation: new THREE.Vector3(-0.64, -0.98, -0.22),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(649.42, 284.15, -1345.66),
        rotation: new THREE.Vector3(-0.26, -0.41, 0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(647.40, 265.31, -1414.22),
        rotation: new THREE.Vector3(-0.39, 0.33, 0.57),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(571.36, 231.09, -1445.90),
        rotation: new THREE.Vector3(-0.44, 1.02, 0.63),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(497.10, 211.99, -1479.22),
        rotation: new THREE.Vector3(-0.57, 1.15, 0.79),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(449.52, 204.95, -1501.22),
        rotation: new THREE.Vector3(-0.28, 0.51, 0.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(454.77, 183.50, -1542.44),
        rotation: new THREE.Vector3(-0.62, -0.21, -0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(492.07, 142.71, -1599.34),
        rotation: new THREE.Vector3(-0.39, 0.03, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(472.58, 132.25, -1639.49),
        rotation: new THREE.Vector3(-0.36, 0.47, 0.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(455.91, 85.66, -1697.09),
        rotation: new THREE.Vector3(-0.32, 0.09, -0.02),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(496.92, 59.14, -1763.62),
        rotation: new THREE.Vector3(-0.54, -0.92, -0.37),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(579.49, 32.47, -1796.92),
        rotation: new THREE.Vector3(-0.56, -1.23, -0.58),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(661.07, 5.97, -1842.32),
        rotation: new THREE.Vector3(-0.45, -0.94, -0.35),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(706.15, -3.03, -1845.91),
        rotation: new THREE.Vector3(0.1, -1.38, 0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5,
  }],
  ['Branka0L', {
    name: 'Branka0L',
    data: [
      {
        offset: new THREE.Vector3(444.95, 505.14, -778.28),
        rotation: new THREE.Vector3(0.00, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(464.56, 480.71, -852.09),
        rotation: new THREE.Vector3(-0.36, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(473.95, 461.90, -927.83),
        rotation: new THREE.Vector3(-0.22, -0.14, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(448.72, 437.57, -974.03),
        rotation: new THREE.Vector3(-0.21, 0.69, -0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(428.14, 423.39, -1032.73),
        rotation: new THREE.Vector3(-0.42, -0.23, -0.22),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(468.87, 402.09, -1079.51),
        rotation: new THREE.Vector3(-0.33, -1.24, -0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(532.83, 401.07, -1080.94),
        rotation: new THREE.Vector3(0.06, -1.17, 0.32),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(546.54, 391.61, -1108.50),
        rotation: new THREE.Vector3(-0.30, -0.01, 0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(518.90, 375.80, -1143.54),
        rotation: new THREE.Vector3(-0.32, 0.34, 0.21),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(506.59, 363.31, -1193.74),
        rotation: new THREE.Vector3(-0.29, -0.37, 0.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(536.07, 344.55, -1222.93),
        rotation: new THREE.Vector3(-0.43, -0.37, -0.17),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(529.45, 316.76, -1278.84),
        rotation: new THREE.Vector3(-0.26, -0.31, 0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(581.64, 302.35, -1299.57),
        rotation: new THREE.Vector3(-1.03, -1.37, -0.66),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(611.97, 296.42, -1309.16),
        rotation: new THREE.Vector3(-0.46, -1.03, -0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(636.19, 279.55, -1348.51),
        rotation: new THREE.Vector3(-0.33, -0.28, 0.26),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(636.39, 261.10, -1406.30),
        rotation: new THREE.Vector3(-0.45, 0.45, 0.56),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(566.36, 228.56, -1433.27),
        rotation: new THREE.Vector3(-0.44, 0.95, 0.56),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(492.73, 211, -1466.31),
        rotation: new THREE.Vector3(-0.27, 1.23, 0.27),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(438.34, 208.20, -1494.36),
        rotation: new THREE.Vector3(-0.08, 0.50, -0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(441.91, 183.43, -1546.54),
        rotation: new THREE.Vector3(-0.57, -0.27, -0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(478.85, 143.67, -1599.54),
        rotation: new THREE.Vector3(-0.41, 0.04, -0.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(463.07, 133.83, -1633.72),
        rotation: new THREE.Vector3(-0.34, 0.60, 0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(442.33, 84.93, -1698.05),
        rotation: new THREE.Vector3(-0.35, -0.04, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(489.08, 58.13, -1774.60),
        rotation: new THREE.Vector3(-0.35, -0.93, -0.22),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(574.36, 32.24, -1809.40),
        rotation: new THREE.Vector3(-0.68, -1.22, -0.60),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(654.15, 6.46, -1853.91),
        rotation: new THREE.Vector3(-0.45, -1.07, -0.44),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(694.37, -3.03, -1877.32),
        rotation: new THREE.Vector3(-3.04, 1.05, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5,
  }],
])

/**
 * Konfig batching objektov pre dlhsiu trat
 */
export const batchingConfigLongTrack: Map<string, BatchingObjectData> = new Map([
  ['Branka0R', {
    name: 'Branka0R',
    data: [
      {
        offset: new THREE.Vector3(105.63, 846.65, -97.77),
        rotation: new THREE.Vector3(-0.19, 0.01, 0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(91.18, 832.26, -134.79),
        rotation: new THREE.Vector3(-0.47, 0.55, 0.23),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(66.75, 814.66, -163.85),
        rotation: new THREE.Vector3(-0.51, 0.01, 0.18),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(95.52, 803.01, -187.70),
        rotation: new THREE.Vector3(-0.13, -1.04, 0.29),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(126.89, 786.20, -210.47),
        rotation: new THREE.Vector3(-0.55, -0.54, -0.09),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(129.01, 766.33, -255.32),
        rotation: new THREE.Vector3(-0.33, 0.32, 0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(94.51, 749.66, -286.29),
        rotation: new THREE.Vector3(-0.72, 1.02, 0.48),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(52.69, 743.47, -291.40),
        rotation: new THREE.Vector3(-0.25, 1.32, -0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(30.26, 738.37, -313.56),
        rotation: new THREE.Vector3(-0.24, -0.07, 0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(59.60, 729.97, -342.74),
        rotation: new THREE.Vector3(-0.39, -0.91, -0.29),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(100.54, 720.89, -352.77),
        rotation: new THREE.Vector3(-0.40, -1.03, -0.21),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(141.07, 705.59, -373.41),
        rotation: new THREE.Vector3(-0.92, -0.92, -0.48),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(161.92, 686.84, -429.36),
        rotation: new THREE.Vector3(-0.21, 0.32, 0.30),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(118.05, 672.87, -454.78),
        rotation: new THREE.Vector3(-0.47, 0.77, 0.36),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(89.07, 656.47, -482.31),
        rotation: new THREE.Vector3(-0.37, 0.17, 0.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(106.13, 643.95, -512.16),
        rotation: new THREE.Vector3(-0.53, -0.81, -0.30),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(149.57, 635.51, -521.13),
        rotation: new THREE.Vector3(-0.71, -1.22, -0.64),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(198.98, 622.73, -517.49),
        rotation: new THREE.Vector3(-1.88, -1.25, -1.77),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(231.83, 612.42, -481.45),
        rotation: new THREE.Vector3(-2.92, -0.94, -2.66),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(286.05, 611.48, -465.19),
        rotation: new THREE.Vector3(-0.33, -1.16, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(319.22, 601.05, -510.88),
        rotation: new THREE.Vector3(-0.37, -0.39, 0.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(329.26, 566.70, -582.85),
        rotation: new THREE.Vector3(-0.16, -0.16, 0.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(353.97, 554.16, -636.92),
        rotation: new THREE.Vector3(-0.28, -0.61, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(412.21, 540.12, -642.47),
        rotation: new THREE.Vector3(-0.89, -1.27, -0.62),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(454.40, 528.95, -671.04),
        rotation: new THREE.Vector3(-0.41, -1.02, -0.27),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(477.81, 517.75, -720.35),
        rotation: new THREE.Vector3(-0.22, 0.19, 0.18),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(459.74, 506.48, -778.89),
        rotation: new THREE.Vector3(-0.20, 0.06, 0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(478.04, 481.23, -851.99),
        rotation: new THREE.Vector3(-0.38, -0.14, -0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(487.39, 461.64, -926.43),
        rotation: new THREE.Vector3(-0.20, -0.12, -0.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(457.63, 435.77, -983.94),
        rotation: new THREE.Vector3(-0.29, 0.79, 0.19),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(441.34, 421.04, -1030.11),
        rotation: new THREE.Vector3(-0.22, -0.20, -0.19),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(474.14, 404.48, -1066.92),
        rotation: new THREE.Vector3(-0.65, -1.41, -0.41),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(538.43, 403.65, -1068.68),
        rotation: new THREE.Vector3(-0.20, -1.08, -0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(559.97, 393.94, -1107.68),
        rotation: new THREE.Vector3(-0.31, -0.09, 0.12),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(530.68, 376.44, -1149.64),
        rotation: new THREE.Vector3(-0.32, 0.41, 0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(519.56, 365.70, -1189.75),
        rotation: new THREE.Vector3(-0.30, -0.40, 0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(548.85, 344.12, -1218.39),
        rotation: new THREE.Vector3(-0.36, -0.28, -0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(541.54, 319.03, -1272.95),
        rotation: new THREE.Vector3(-0.32, -0.33, 0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(585.41, 308.77, -1286.35),
        rotation: new THREE.Vector3(-1.06, -1.33, -0.55),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(619.20, 301.23, -1297.68),
        rotation: new THREE.Vector3(-0.28, -0.99, 0.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(649.42, 284.15, -1345.66),
        rotation: new THREE.Vector3(-0.26, -0.41, 0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(647.40, 265.31, -1414.22),
        rotation: new THREE.Vector3(-0.39, 0.33, 0.57),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(572.08, 231.31, -1445.63),
        rotation: new THREE.Vector3(-0.44, 1.02, 0.63),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(497.10, 211.99, -1479.22),
        rotation: new THREE.Vector3(-0.57, 1.15, 0.79),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(449.52, 204.95, -1501.22),
        rotation: new THREE.Vector3(-0.28, 0.51, 0.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(454.77, 183.50, -1542.44),
        rotation: new THREE.Vector3(-0.62, -0.21, -0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(492.07, 142.71, -1599.34),
        rotation: new THREE.Vector3(-0.39, 0.03, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(472.58, 132.34, -1639.49),
        rotation: new THREE.Vector3(-0.36, 0.47, 0.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(455.91, 85.66, -1697.09),
        rotation: new THREE.Vector3(-0.32, 0.09, -0.02),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(496.92, 59.14, -1763.62),
        rotation: new THREE.Vector3(-0.54, -0.92, -0.37),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(579.49, 32.47, -1796.92),
        rotation: new THREE.Vector3(-0.56, -1.23, -0.58),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(661.07, 5.97, -1842.32),
        rotation: new THREE.Vector3(-0.45, -0.94, -0.35),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(705.883, 0.174, -1846.316),
        rotation: new THREE.Vector3(-2.71, 1.39, 2.80),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5,
  }],
  ['Branka0L', {
    name: 'Branka0L',
    data: [
      {
        offset: new THREE.Vector3(92.06, 846.19, -97.53),
        rotation: new THREE.Vector3(-0.26, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(80.11, 834.08, -128.43),
        rotation: new THREE.Vector3(-0.54, 0.59, 0.16),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(53.13, 812.40, -162.44),
        rotation: new THREE.Vector3(-0.53, 0.21, 0.12),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(88.57, 797.25, -198.87),
        rotation: new THREE.Vector3(-0.09, -0.99, 0.23),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(114.28, 784.27, -216.11),
        rotation: new THREE.Vector3(-0.50, -0.37, -0.17),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(116.40, 767.23, -250.59),
        rotation: new THREE.Vector3(-0.38, 0.33, 0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(87.63, 752.04, -274.55),
        rotation: new THREE.Vector3(-0.68, 1.02, 0.36),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(50.22, 747.17, -278.17),
        rotation: new THREE.Vector3(0.19, 1.36, -0.51),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(16.94, 738.50, -315.34),
        rotation: new THREE.Vector3(-0.33, -0.13, -0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(50.97, 730.12, -353.21),
        rotation: new THREE.Vector3(-0.33, -0.92, -0.37),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(94.26, 718.55, -364.40),
        rotation: new THREE.Vector3(-0.41, -1.05, -0.26),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(134.98, 701.27, -384.78),
        rotation: new THREE.Vector3(-0.71, -1.05, -0.37),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(149.96, 685.42, -422.95),
        rotation: new THREE.Vector3(-0.14, 0.28, 0.09),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(109.42, 674.52, -444.38),
        rotation: new THREE.Vector3(-0.50, 0.82, 0.31),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(76.19, 657.53, -479.96),
        rotation: new THREE.Vector3(-0.17, 0.26, -0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(100.31, 642.76, -524.50),
        rotation: new THREE.Vector3(-0.45, -0.97, -0.39),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(147.13, 636.13, -534.60),
        rotation: new THREE.Vector3(-0.42, -1.37, -0.54),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(198.85, 622.81, -530.91),
        rotation: new THREE.Vector3(-1.27, -1.42, -1.36),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(239.79, 609.88, -492.39),
        rotation: new THREE.Vector3(-3.11, -0.81, -3.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(281.87, 608.10, -477.54),
        rotation: new THREE.Vector3(-0.15, -1.15, 0.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(306.76, 599.74, -515.84),
        rotation: new THREE.Vector3(-0.40, -0.38, -0.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(315.88, 568.17, -584.26),
        rotation: new THREE.Vector3(-0.19, -0.18, -0.13),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(344.87, 552.25, -646.83),
        rotation: new THREE.Vector3(-0.24, -0.56, -0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(410.50, 538.01, -655.84),
        rotation: new THREE.Vector3(-0.50, -1.20, -0.43),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(448.23, 528.75, -682.80),
        rotation: new THREE.Vector3(-0.32, -1.09, -0.31),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(464.73, 516.97, -718.28),
        rotation: new THREE.Vector3(-0.15, 0.25, 0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(444.95, 506.19, -778.28),
        rotation: new THREE.Vector3(-0.25, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(464.56, 480.71, -852.09),
        rotation: new THREE.Vector3(-0.36, 0.00, 0.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(473.95, 461.90, -927.83),
        rotation: new THREE.Vector3(-0.22, -0.14, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(448.56, 437.63, -973.85),
        rotation: new THREE.Vector3(-0.21, 0.69, -0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(428.14, 423.39, -1032.73),
        rotation: new THREE.Vector3(-0.42, -0.23, -0.22),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(468.87, 402.10, -1079.51),
        rotation: new THREE.Vector3(-0.33, -1.24, -0.25),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(532.83, 400.99, -1080.94),
        rotation: new THREE.Vector3(-0.06, -1.16, 0.09),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(546.54, 391.61, -1108.50),
        rotation: new THREE.Vector3(-0.30, -0.01, 0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(518.90, 375.80, -1143.54),
        rotation: new THREE.Vector3(-0.32, 0.34, 0.21),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(507.09, 362.97, -1194.61),
        rotation: new THREE.Vector3(-0.34, -0.36, -0.02),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(536.07, 344.55, -1222.93),
        rotation: new THREE.Vector3(-0.43, -0.37, -0.17),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(529.45, 316.76, -1278.84),
        rotation: new THREE.Vector3(-0.26, -0.31, 0.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(581.64, 302.39, -1299.61),
        rotation: new THREE.Vector3(-1.10, -1.37, -0.66),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(611.97, 296.42, -1309.16),
        rotation: new THREE.Vector3(-0.46, -1.03, -0.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(636.19, 279.55, -1348.51),
        rotation: new THREE.Vector3(-0.33, -0.28, 0.26),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(636.39, 261.19, -1406.30),
        rotation: new THREE.Vector3(-0.45, 0.45, 0.56),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(565.57, 228.26, -1433.57),
        rotation: new THREE.Vector3(-0.44, 0.95, 0.56),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(492.73, 211, -1466.31),
        rotation: new THREE.Vector3(-0.27, 1.23, 0.27),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(438.75, 208.28, -1493.70),
        rotation: new THREE.Vector3(-0.08, 0.50, -0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(441.91, 183.43, -1546.54),
        rotation: new THREE.Vector3(-0.57, -0.27, -0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(478.85, 143.67, -1599.54),
        rotation: new THREE.Vector3(-0.41, 0.04, -0.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(463.07, 133.81, -1633.72),
        rotation: new THREE.Vector3(-0.34, 0.60, 0.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(442.33, 84.93, -1698.05),
        rotation: new THREE.Vector3(-0.35, -0.04, -0.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(489.08, 58.13, -1774.60),
        rotation: new THREE.Vector3(-0.35, -0.93, -0.22),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(574.36, 32.24, -1809.40),
        rotation: new THREE.Vector3(-0.68, -1.22, -0.60),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(654.15, 6.46, -1853.91),
        rotation: new THREE.Vector3(-0.45, -1.07, -0.44),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(693.351, 0.028, -1875.188),
        rotation: new THREE.Vector3(0.10, -1.38, 0.15),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5,
  }],
])
