import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import {
  GateAnimationsNames,
  PlayerAnimationsNames
} from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {
  [PlayerAnimationsNames.carve]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.carveLeft]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.carveRight]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.jump]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.skating]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.preStart]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.start]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.tuck]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.tuckTurnLeft]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.tuckTurnRight]: {
    loop: true,
    starting: false
  },
  /*
   * [PlayerAnimationsNames.fall]: {
   *     loop: false,
   *     starting: false
   * },
   */
  [PlayerAnimationsNames.stop]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.bad]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.neutral]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: true,
    starting: false
  }
}

export const gateAnimationsConfig: AnimationsSettingRepository = {
  [GateAnimationsNames.left]: {
    loop: false,
    starting: false
  },
  [GateAnimationsNames.right]: {
    loop: false,
    starting: false
  },
  [GateAnimationsNames.straight]: {
    loop: false,
    starting: false
  }
}
