/**
 * trieda pre koefy pri zvukoch
 */
export const audioGameConfig = {

  /** akych komentatorov davame pri split times */
  splitTimeDifference: {

    /** plus1 */
    first: 0.25,

    /** plus2 */
    second: 0.5

  },

  /** pri ktorej branke pred koncom zahrajeme koment beforeFinish */
  commentBeforeFinish: 3

}
