import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(407.4974, 445.40463, -1024.44177),
      endPosition: new THREE.Vector3(501.00369, 422.7, -1059.17),
      startRotation: new THREE.Vector3(1.424, -0.300, 1.017),
      endRotation: new THREE.Vector3(1.24, -0.042, 0.7976),
      duration: 5,
      notSkippableFrames: 20,
      fov: 81
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(556.083435, 39.967, -1807.09545898),
      endPosition: new THREE.Vector3(568.627, 39.967, -1785.14172),
      startRotation: new THREE.Vector3(1.6258, 0.0214, 1.1674),
      endRotation: new THREE.Vector3(1.5699, -0.09057, 0.8505),
      duration: 3,
      notSkippableFrames: 20,
      fov: 84
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(669.5, 20, -1848.5),
      startLookAt: new THREE.Vector3(730, 1, -1873),
      endPosition: new THREE.Vector3(670.5, 20, -1851.5),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(453.2128, 514.8636, -757.3865),
        endPosition: new THREE.Vector3(453.73727, 519.1853, -744.359558),
        startRotation: new THREE.Vector3(1.2918, 0.10787, -3.13787),
        endRotation: new THREE.Vector3(1.25856, -0.1189, 3.09508),
        duration: 3,
        fov: 60
      },
      {
        startPosition: new THREE.Vector3(454.6462, 522.14453, -724.555),
        endPosition: new THREE.Vector3(453.43246, 522.1611, -724.85278),
        startRotation: new THREE.Vector3(1.54258, 0.005388, -0.18844),
        endRotation: new THREE.Vector3(1.54997, -0.00125, 0.06018),
        duration: 3,
        fov: 60
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(3, 1.5, 2),
      endPosition: new THREE.Vector3(0, 1.5, -3),
      duration: 6,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  tweenSettingsForCameraStatesLong: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(409.888, 454.708, -993.4019),
      endPosition: new THREE.Vector3(506.95, 414.72, -1071.142),
      startRotation: new THREE.Vector3(1.503, -0.288, 0.712),
      endRotation: new THREE.Vector3(1.312, 0.147, 0.792),
      duration: 5,
      notSkippableFrames: 20,
      fov: 84
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(558.425, 39.823, -1806.389),
      endPosition: new THREE.Vector3(568.548, 39.163, -1786.537),
      startRotation: new THREE.Vector3(1.602, -0.096, 1.126),
      endRotation: new THREE.Vector3(1.602, -0.072, 0.885),
      duration: 3,
      notSkippableFrames: 20,
      fov: 84
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(669.5, 20, -1848.5),
      startLookAt: new THREE.Vector3(730, 1, -1873),
      endPosition: new THREE.Vector3(670.5, 20, -1851.5),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(97.579, 852.49, -89.001),
        endPosition: new THREE.Vector3(96.875, 856.777, -76.524),
        startRotation: new THREE.Vector3(1.247, 0.139, -3.127),
        endRotation: new THREE.Vector3(1.225, -0.0807899, 3.072),
        duration: 3,
        fov: 60
      },
      {
        startPosition: new THREE.Vector3(97.3667755, 860.465, -56.97999),
        endPosition: new THREE.Vector3(98.400505, 860.427368, -57.6599),
        startRotation: new THREE.Vector3(1.4819284, -0.00425, 0.0478),
        endRotation: new THREE.Vector3(1.482, 0.02268, -0.249454),
        duration: 3,
        fov: 60
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(3, 1.5, 2),
      endPosition: new THREE.Vector3(0, 1.5, -3),
      duration: 6,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
