import {
  CANNON,
  THREE
} from '@powerplay/core-minigames'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** Pocet pokusov */
  numberOfAttempts: 1,

  /**
   * Maximalna rychlost hraca v normalnom postoji
   * DEFAULT: 20
   */
  maxSpeedNormal: 100/* 20 */,

  /**
   * Maximalna rychlost hraca v zjazdovom postoji
   * DEFAULT: 25
   */
  maxSpeedCrouch: 100,

  /**
   * Koeficient pre spomalovanie vo vzduchu
   * DEFAULT: 0.95
   */
  inAirSlowingCoef: 0.95,

  /**
   * How much to damp the body velocity each step. 0-1
   * DEFAULT: 0.01
   */
  linearDamping: 0.001,

  /**
   * Hmotnost lyziara
   * DEFAULT: 1
   */
  playerMass: 90,

  /**
   * Trenie lyziara s kopcom
   * DEFAULT: 0.3
   */
  frictionHillPlayer: /* 0.01 */ 0.0000001,

  /**
   * Tzv bounciness alebo skakavost lyziara vzhladom na kopec
   * DEFAULT: 0.3
   */
  restitutionHillPlayer: 0,

  /**
   * Relaxation time of the produced friction equations
   * DEFAULT: 3
   */
  frictionEquationRelaxationHillPlayer: 3,

  /**
   * Stiffness of the produced friction equations
   * DEFAULT: 1e7
   */
  frictionEquationStiffnessHillPlayer: 10000000,

  /**
   * Relaxation time of the produced contact equations
   * DEFAULT: 3
   */
  contactEquationRelaxationHillPlayer: 3,

  /**
   * Stiffness of the produced contact equations
   * DEFAULT: 1e7
   */
  contactEquationStiffnessHillPlayer: 10000000,

  /**
   * Gravitacia sveta
   * DEFAULT: (0, -14.31, 0)
   */
  gravitation: new CANNON.Vec3(0, -9.81, 0),

  /**
   * Ako velmi zoberieme do uvahy fyziku sveta pri zmene uhla, aby nerobila taky odpor, resp aby
   * mali vacsiu vahu inputy hraca. Napr ked je uhol 2.14 a podla fyziky by sa mal zmenit na 2.02,
   * tak pri tejto hodnote 0.2 dame uhol 2.116 a tym padom viac zostane nas smer nez ten, ktory
   * nam urcuje fyzika
   * DEFAULT: 0.2
   */
  percentPhysicsRotationChange: 0.2,

  /**
   * minimalna zaciatocna rychlost
   * DEF 6
   */
  minStartVelocity: 6,

  /**
   * maximalny bonus ku startu
   * DEF 2
   */
  maxStartBarBonus: 2,

  /**
   * koeficient zrychlenia pri starte
   * DEF: 0.1
   */
  startSpeedUpCoef: 0.1,

  /**
   * max koeficient priratany k rychlosti podla velkosti atributu
   */
  maxAtrBonusStart: 2,

  /**
   * dlzka rozbehu
   * DEF: 60
   */
  speedUpFrames: 40,

  /**
   * Koeficient pre priliehanie hraca ku trati, prenasobuje sa tymto koeficientom, idealne je
   * toto nastavenie menit opatrne (napr pri 1.1 sa stavalo, ze isiel pod trat lahko)
   * DEFAULT: 0.8
   */
  endContactCoef: 0.8,

  /**
   * Koeficient pre gravitaciu vo vzduchu, dany koeficient sa prirata ku default gravitacii
   * DEFAULT: -30
   */
  gravityCoefInAir: -30,

  /**
   * Koeficient pre gravitaciu vo vzduchu pri malej vzdialenosti, ktora je urcena cez maxDistance
   * nizsie, dany koeficient sa prirata ku default gravitacii
   * DEFAULT: -15
   */
  gravityCoefInAirNearTrack: 0,

  /**
   * Minimalna vzdialenost od povrchu kopca pre specialnu gravitaciu
   * DEFUALT: 0.3
   */
  minDistanceForGravityCoefinAirNearTrack: 0.3,

  /**
   * Maximalna vzdialenost od povrchu kopca pre specialnu gravitaciu
   * DEFUALT: 1
   */
  maxDistanceForGravityCoefinAirNearTrack: 1,

  /**
   * Hodnota vzdialenosti na y osi, ktora urcuje, ze nad nou sa uz bude pocitat, ze je hrac vo
   * vzduchu
   * DEFAULT: 1.8
   */
  distanceToBeInAir: 1.8,

  /**
   * Hodnota vzdialenosti na y osi, ktora urcuje
   * ako vysoko nad zemou musi hrac byt, aby sa mohol odlepit
   */
  distanceToUnstick: 1.2,

  /**
   * offset prilepenia hraca na svah
   */
  playerModelOffset: 0.3,

  /**
   * Upravujuca hodnota hodnoty playerModelOffset pre fix prepadavania
   */
  landingValueFix: 0.3,

  /**
   * Pociatocny smer pre velocity
   */
  startVelocityDirection: new CANNON.Vec3(0, 0, -1),

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 1.6, -3),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0.8, 10),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  /**
   * starting position
   * POZN.: cannon ve3 to je kvoli tomu, aby sedel vypocet distanceTo aj v cannon svete
   */
  startPosition: new CANNON.Vec3(453.7, 522.5, -729.85),

  /**
   * starting position pre dlhsiu trat
   * POZN.: cannon ve3 to je kvoli tomu, aby sedel vypocet distanceTo aj v cannon svete
   */
  startPositionLongTrack: new CANNON.Vec3(97.6, /* 851.95 */860.5, -62.1),

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   */
  skipToFinish: {

    active: false,
    position: new CANNON.Vec3(641.07, 20, -1812.32)

  },

  /**
   * Offset od ciela v m, kde uz bude maxAudience hlasitost
   */
  maxAudienceOffsetFromFinish: 250,

  /**
   * na akej pozicii je max ozvucenie divakov
   */
  maxAudienceValPos: new CANNON.Vec3(701, 0.66, -1860),

  /**
   * aku minimalnu hlasitost maju divaci
   */
  minAudienceSound: 0.2,

  /**
   * aku maximalnu hlasitost maju divaci
   */
  maxAudienceSound: 1,

  /**
   * pri akom velkom zatoceni zahrajeme zvuk zatocenia
   */
  soundOnTurn: 0.3,

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * Limit ked uz nie je tuck ale carve (tj nad touto hodnotou z jednotkovej kruznice uz nebude
   * tuck, ale dame carve)
   */
  autoTuckLimit: 0.6,

  /**
   * Koeficient pre lerp normal na kopci, aby nebol prechod medzi normalami "hranaty"
   */
  hillNormalLerpCoef: 0.1,

  /**
   * Koeficient pre lerp normal na kopci, ale ked je hrac vo vzduchu
   */
  hillNormalLerpCoefInAir: 0.005,

  /**
   * Koeficient pre lerp hracovej rotacie, aby tiez nebol prechod medzi rotaciami "hranaty"
   */
  playerRotationLerpCoef: 0.2,

  /**
   * Koeficient pre lerp velocity na Y osi kvoli traseniu kamery
   */
  velocityYLerpCoef: 0.05,

  /**
   * Penalizacia v sekundach
   */
  penaltySeconds: 2,

  /**
   * Pocet sekund po ktorych ho vyhodime z hry ak je mimo trate
   */
  outOfBoundsSeconds: 3,

  /**
   * Hlbka v metroch kolko pod tratou ma byt, aby sa pocital ako mimo trate
   */
  depthOutOfBounds: 0.5,

  /** polomer checkpoint valcov 0 fyzicky trigger */
  checkpointsDiameter: 1.2,

  /** povolenie mechanizmu proti spomalovaniu */
  antiSlowEnabled: true,

  /** ako velmi sa musi spomalit rychlost na xy rovine, aby sa reagovalo */
  antiSlowThreshold: 2,

  /** ako velmi sa musi zrychlit rychlost na xy rovine, aby sa reagovalo */
  antiBoostThreshold: 2

}
