import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.commonSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    sprite: {
      'DH_checkpoint_fail': [
        0,
        1224.5804988662132
      ],
      'DH_checkpoint_good': [
        3000,
        1462.3129251700675
      ],
      'DH_crash': [
        6000,
        1099.9999999999995
      ],
      'DH_skiing_break': [
        9000,
        6719.999999999999
      ],
      'DH_skiing_jump': [
        17000,
        1201.8140589569164
      ],
      'DH_skiing_landing': [
        20000,
        1612.3809523809527
      ],
      'audience_sad': [
        23000,
        10008.004535147393
      ],
      'audience_yay': [
        35000,
        8295.01133786848
      ],
      'countdown_beep_long': [
        45000,
        870.6122448979556
      ],
      'countdown_beep_short': [
        47000,
        755.8503401360567
      ]
    }
  },
  {
    files: [
      AudioNames.loopedSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    version: 2,
    category: AudioCategories.sprite,
    sprite: {
      'audience_hype': [
        0,
        7723.174603174603
      ],
      'DH_skiing_loop': [
        9000,
        14759.999999999998
      ]
    }
  },
  {
    files: [
      AudioNames.randomSound
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    subset: true,
    sprite: {
      'DH_gatepass_var03': [
        0,
        938.2766439909296
      ],
      'DH_gatepass_var031': [
        2000,
        983.4693877551022
      ],
      'DH_gatepass_var032': [
        4000,
        883.0839002267572
      ],
      'DH_gatepass_var033': [
        6000,
        1104.7619047619044
      ]
    }
  },
  {
    files: [
      AudioNames.commentatorSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'finish_1': [
        0,
        6888.480725623583
      ],
      'finish_2': [
        8000,
        7347.845804988662
      ],
      'finish_3': [
        17000,
        5275.2154195011335
      ],
      'finish_4': [
        24000,
        4141.315192743765
      ],
      'missed_gate': [
        30000,
        2968.480725623586
      ],
      'missed_gate2': [
        34000,
        3289.4104308390056
      ],
      'split_times_minus': [
        39000,
        3000.045351473922
      ],
      'split_times_minus2': [
        44000,
        7917.8458049886585
      ],
      'split_times_minus3': [
        53000,
        2657.2335600907027
      ],
      'split_times_minus4': [
        57000,
        3215.827664399093
      ],
      'split_times_plus_1': [
        62000,
        5182.312925170067
      ],
      'split_times_plus_12': [
        69000,
        3201.088435374146
      ],
      'split_times_plus_2': [
        74000,
        6342.4036281179215
      ],
      'split_times_plus_22': [
        82000,
        4376.5986394557785
      ],
      'split_times_plus_3': [
        88000,
        7249.0702947845875
      ],
      'split_times_plus_32': [
        97000,
        4402.721088435371
      ]
    }
  }
]