export const finishPhaseConfig = {

  /** ako dlho ma trvat pohyb v cieli v sekundach */
  endActionCurveTime: 4,

  /** Configy physicsBody sirka */
  PHYSICS_WIDTH: 100,

  /** Configy physicsBody vyska */
  PHYSICS_HEIGHT: 20,

  /** Configy physicsBody hlbka */
  PHYSICS_DEPTH: 1,

  /** rychlost v polovici animacie */
  ANIMATION_SPEED_IN_HALF: 0.99,

  /** rychlost v tristvrtinach animacie */
  ANIMATION_SPEED_IN_THIRD: 0.9999,

  /** Rotacia na zaciatku */
  ROTATION_BEGINNING_PER_FRAME: -0.05,

  /** Rotacia po zaciatku do stredu */
  ROTATION_MIDDLE_PER_FRAME: 0.03,

  /** rychlost nad ktoru specialne spomalujeme lyziara v cieli */
  softSpeedLimit: {

    // nad aku rychlost sa aplikuje coef
    speed: 1.5,

    // koeficient ktorym spomalujeme kazdy frame
    coef: 0.95

  }

}
