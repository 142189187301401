import type { TrainingTaskType } from '@powerplay/core-minigames-ui'
import { defineStore } from 'pinia'

export interface TrainingState {
  newHighScore: number,
  showNewHighScore: boolean,
  tasks: TrainingTaskType[],
  firstTutorialMessage: boolean
}

const initialState = (): TrainingState => ({
  newHighScore: 0,
  showNewHighScore: false,
  tasks: [],
  firstTutorialMessage: false
})

export const trainingState = defineStore('trainingState', {
  state: initialState,
  actions: {

    addTask(task: TrainingTaskType) {

      this.tasks.push(task)

    },
    editTask(editedTask: TrainingTaskType) {

      const index = this.tasks.findIndex((task) => {

        return task.text === editedTask.text

      })
      if (index === -1) {

        this.tasks.push(editedTask)

      } else {

        this.tasks[index] = editedTask

      }

    }
  }
})
