/**
 * Konfig pre branky
 */
export const gatesConfig = {

  /** Pocet branok */
  count: 27,
  countLong: 53,

  /** Medzicasy budu na brankach s tymito indexami (od 0 po count - 1) */
  split: [2, 7, 13, 20],
  splitLong: [6, 16, 26, 35, 44],

  /** Branky, pri ktorych zacneme prehravat zvuky zvonov */
  audioGatesStartBells: [1, 6, 12, 19, 24],
  audioGatesStartBellsLong: [2, 15, 25, 34, 43, 50],

  /** Branky pri ktorych prestaneme prehravat zvuky zvonov */
  audioGatesStopBells: [2, 7, 13, 20, 26],
  audioGatesStopBellsLong: [6, 16, 26, 35, 44, 52],

  /** po akych brankach sa zobrazi speedmeter */
  speedmeterOnGate: [10, 20],
  speedmeterOnGateLong: [22, 46],

  /** o kolko m sa ma zmensit trigger (mimo konecnej branky) */
  successTriggerWidth: 0.25,

  /** debug veci pre triggery */
  debugTriggers: {

    /** Ci sa maju zobrazit debug vektory pre triggery branok */
    vectors: false,

    /** Ci sa maju zobrazit debug meshe pre triggery branok */
    meshes: false,

    /** Ci sa maju zobrazit debug body pre triggery branok */
    points: false

  }



}
