<template>
  <game-downhill-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameDownhillComponent from './components/GameDownhillComponent.vue'

export default defineComponent({
  components: {
    GameDownhillComponent
  },
})
</script>
