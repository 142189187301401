import { defineStore } from 'pinia'

export interface PlayerState {
  maxSpeed: number,
  velocityX: number,
  velocityY: number,
  velocityZ: number,
}

const initialState = (): PlayerState => ({
  maxSpeed: 1,
  velocityX: 0,
  velocityY: 0,
  velocityZ: 0
})

export const mainState = defineStore('mainState', {
  state: initialState,
})
