import { defineStore } from 'pinia'

export interface StartMessageState {
  showMessage: boolean,
  messageText: string,
  messageColor: number
}

const initialState = (): StartMessageState => ({
  showMessage: false,
  messageText: '',
  messageColor: 0
})

export const startMessageState = defineStore('startMessageState', {
  state: initialState,
})
