import type { TutorialObjective } from '@powerplay/core-minigames'
import { TutorialMessageColors } from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    offset: boolean
  },
  objectives: TutorialObjective[],
  showObjectives: boolean,
  showButtonStart: boolean,
  showButtonsMovement: boolean,
  showBarStart: boolean,
  settings: boolean
}

const initialState = (): TutorialState => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    offset: false
  },
  objectives: [],
  showObjectives: false,
  showButtonStart: false,
  showButtonsMovement: false,
  showBarStart: false,
  settings: false
})

export const tutorialState = defineStore('tutorialState', {
  state: initialState,
})
