import type {
  LoaderDataTypes,
  MaterialDataObject
} from '@powerplay/core-minigames'

/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  skier = 'skier',
  hill = 'hill',
  hillLong = 'hill_long',
  checkpoint = 'marker',
  gates = 'gates',
  animatedGate = 'branka',
  gatesLong = 'gates_long',
  gatesPositions = 'brankypozicie4',
  gatesPositionsLong = 'brankypozicielong6',
  animatedGateL = 'animatedGateL',
  animatedGateR = 'animatedGateR',
  cameraBoxes = 'cameraBoxesDH',
  cameraBoxesLong = 'cameraBoxesDHL'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'logo',
  flags = 'flags',
  track = 'track',
  transparentSafetyNet = 'TransparentSafetyNet',
  people = 'People',
  lightmapHill = 'LightmapHill',
  lightmapHillLong = 'LightmapHill_Long',
  staticPeople = 'StaticPeople',
  snowParticle = 'snowSSbase',
  smallSnow = 'snowDust',
  smokeScreen = 'testOpacity2',
  marker = 'marker',
  gates = 'Gates',
  startCabin = 'StartCabin'
  // smokeScreen = 'snowRed'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  hill = 'Atlas1',
  hillLong = 'hillLong',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  transparentSafetyNet = 'TransparentSafetyNet',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  checkpointGlow = 'checkpointGlow',
  gates = 'gates',
  startCabin = 'StartCabin'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  carve = 'carve',
  carveLeft = 'carve L',
  carveRight = 'carve R',
  jump = 'jump',
  prepare = 'prepare',
  skating = 'skating',
  preStart = 'prestart 2',
  start = 'start',
  tuck = 'tuck',
  tuckTurnLeft = 'tuck turn L',
  tuckTurnRight = 'tuck turn R',
  fall = 'fall 2',
  stop = 'stop',
  bad = 'bad',
  neutral = 'neutral',
  happy = 'happy'
}

export enum GateAnimationsNames {
  left = 'left',
  right = 'right',
  straight = 'straight',
  none = 'straight original'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  maxSpeedNormal: number
  maxSpeedCrouch: number
  inAirSlowingCoef: number
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}

/** Typy mien lubovolnych assetov */
export type AssetsConfigsNamesTypes = MaterialsNames | TexturesNames | ModelsNames

/** Typy konfigov assetov */
export type AssetsConfigsTypes = MaterialDataObject | LoaderDataTypes

/** Mena assetov pre konfigy */
export enum AssetsConfigsNames {

  textures = 'textures',
  models = 'models',
  materials = 'materials'

}

/** Konfigy assetov (pre vsetky mena) */
export type AssetsConfigs = { [key in AssetsConfigsNames]: AssetsConfigsTypes }
