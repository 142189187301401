import { THREE } from '@powerplay/core-minigames'

/**
 * Particle config na sneh co pada
 */
export const snowParticleConfig = [
  {
    active: false,
    particleAmountPerEmit: 0
  },
  {
    active: true,
    particleAmountPerEmit: 10
  },
  {
    active: true,
    particleAmountPerEmit: 50
  },
  {
    active: true,
    particleAmountPerEmit: 100
  },
  {
    active: true,
    particleAmountPerEmit: 150
  }
]

/**
 * Config na sneh za lyziarom
 */
export const skiParticleConfig = {

  // Rychlost kde sa vobec partikla spusti
  activationVelocitySnow: 20,

  // aktivacna rychlost na smokke keby chceme inokedy
  activationVelocitySmoke: 20,

  // rychlost kde sa emitne max partiklov
  maxParticleEmitSpeed: 50,

  // maximalne mnozstvo partiklov na jeden emit
  maxParticleEmitOnSpeed: 30,

  // Minimum partiklov na emit
  lowestParticleEmitOnSpeed: 3,

  smoke: {
    // Stav v pripade klasickeho jazdu vopred
    defaultState: {
      particleSpan: new THREE.Vector3(0.2, 0.3, 0.1),
      particleSize: [0.3, 0.5],
      randomPower: [-0.01, 0.01]
    },

    // Stav v pripade lavej zakrut
    leftState: {
      particleSpan: new THREE.Vector3(-0.4, 0.3, 0.1),
      particleSize: [0.3, 0.5],
      randomPower: [-0.01, 0.01]
    },

    // stav v pripade pravej zakruty
    rightState: {
      particleSpan: new THREE.Vector3(0.4, 0.3, 0.1),
      particleSize: [0.3, 0.5],
      randomPower: [-0.01, 0.01]
    }
  },
  snow: {
    // Stav v pripade klasickeho jazdu vopred
    defaultState: {
      particleSpan: new THREE.Vector3(0.2, 0.3, 0.1),
      particleSize: [0.008, 0.01],
      randomPower: [-0.01, 0.01]
    },

    // Stav v pripade lavej zakrut
    leftState: {
      particleSpan: new THREE.Vector3(-0.3, 0.3, 0.1),
      particleSize: [0.008, 0.01],
      randomPower: [-0.01, 0.01]
    },

    // stav v pripade pravej zakruty
    rightState: {
      particleSpan: new THREE.Vector3(0.3, 0.3, 0.1),
      particleSize: [0.008, 0.01],
      randomPower: [-0.01, 0.01]
    }
  },
  finishPhase: {
    particleSpan: new THREE.Vector3(0.6, 0.6, 0.6),
    particleAmount: 20,
    velocity: new THREE.Vector3(0, 1.5, -8),
    particleSpanBreak: new THREE.Vector3(0.8, 0.8, 0.8),
    particleVelocityBreak: new THREE.Vector3(-5, 2, 2)
  }
}

/** Skalar na posun partiklov pocas hry */
export const gameMultiplicationScalar = 10
