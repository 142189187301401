/** Konfig pre nastavenie hraca a jeho ovladania */
export const unitCyclesConfig = {

  /** Veci pre jednotkovu kruznicu hracovych inputov */
  playerInputs: {

    /**
     * Rozsah na obe strany po jednotkovej kruznici. Zacina sa na 0, co je smer hore. Vieme ist
     * potom v rozsahu + a -, napr ak je 90 stupnov, tak vieme ist po -90 stupnov vlavo a
     * 90 stupnov vpravo
     * HODNOTA v radianoch
     */
    maxTurnAngle: Math.PI / 5,

    /**
     * Skratenie rozsahu, ked je lyziar v zjazdovom postoji
     * HODNOTA v radianoch
     */
    maxTuckAngle: Math.PI / 7,

    /**
     * Ked ideme z hlbky jednej strany do druhej, tak si skratime "cestu"
     * HODNOTA v radianoch
     */
    maxAngleFast: Math.PI / 4,

    /**
     * Koeficient, ktory sa pridava kazdy 1 frame. Je to prirastok, napr ked sme na hodnote 0,
     * prirastok je 0.3 a mame stlacene inputy vpravo, tak sa hodnota meni po frameoch
     * nasledovne: 0 .. 0.3 .. 0.6 .. 0.9 az kym dosiahne maximum range, kde nepokracuje dalej
     * HODNOTA v radianoch
     */
    coefAdd: 0.05, // koeficient pridavania za 1 frame pri drzani inputu

    /**
     * Hodnota ktorou sa hráč otáča automaticky späť na center ak sa nedrží žiadne tlačidlo
     * HODNOTA v radianoch
     */
    coefReturnToCenter: 0.05,

    /**
     * Koeficient pre redistribuciu na x a z osi
     * HODNOTA v radianoch
     */
    coefDirectionChange: 0.03

  },

  /** Veci pre silu, ktora pohana vpred */
  forwardForce: {

    /**
     * Koeficient prirastku pre velocity pri zjazdovom postoji
     */
    coefTuck: 0.02

  }

}
