import {
  game,
  ParticleEmitter,
  particleManager,
  ParticleMaterialType,
  THREE,
  fpsManager,
  displayManager,
  cameraManager
} from '@powerplay/core-minigames'
import type { ParticleOptionsType } from '@powerplay/core-minigames'
import {
  skiParticleConfig,
  snowParticleConfig,
  gameMultiplicationScalar
} from './config/particleConfig'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { player } from './player'
import {
  DisciplinePhases,
  type ParticleEmitters,
  ParticleNames,
  PlayerAnimationsNames,
  SkiSide,
  type SnowState,
  TexturesNames
} from './types'

export class ParticleEffects {

  /** Emittery v hre  */
  private emitters: ParticleEmitters = {}

  /** Ci je aktivny state */
  private isActive = true

  /** Ci moze nieco aktivovat emit */
  private stopSkiEmitL = false

  /** Ci moze nieco aktivovat emit */
  private stopSkiEmitR = false

  /** SnowStates */
  private snowingStates: SnowState[] = []

  /** Zakladny konfig pre emitter */
  private _baseConfig?: ParticleOptionsType

  /** Getter base configu */
  public get baseConfig(): ParticleOptionsType {

    if (!this._baseConfig) throw new Error('Partikle baseconfig nie je definovany')
    return this._baseConfig

  }

  /**
   * Konstruktor
   */
  public constructor() {

    this.createSnowParticle()
    this.makeSkiParticle()
    this.createEmittersCache()

  }

  /**
   * Metoda na tvorbu partiklov pri kontruktore
   */
  private createSnowParticle(): void {

    this.snowingStates = snowParticleConfig

    const settingsState = this.randomState()
    if (!settingsState.active) this.isActive = false

    const texture: THREE.Texture = game.getTexture(TexturesNames.snowParticle)

    this._baseConfig = {
      emitRate: 1,
      particleLife: 200,
      blending: THREE.NormalBlending,
      name: ParticleNames.whiteSnow,
      basePosition: new THREE.Vector3(0, 0, 0),
      particleSpan: new THREE.Vector3(50, 50, 50),
      scale: [0.1, 0.2],
      mass: 0.01,
      alpha: [0.5, 1.0],
      gravitation: true,
      randomPower: this.randomizedRandomDrift(),
      randomDrift: true,
      materialType: ParticleMaterialType.shader,
      velocity: new THREE.Vector3(0, -4, 0),
      vertexColors: false,
      // rotation: [-0.3, 0.3],
      offset: [2, 2],
      repeat: [2, 2],
      texture,
      particleAmountPerEmit: 5,
      scene: game.scene,
      fpsLimit: fpsManager.fpsLimit,
      width: displayManager.width,
      height: displayManager.height
    }

    particleManager.createEmitter(this._baseConfig)

  }

  /**
   * Vytvarac configov
   * @param name - meno prvku
   * @param blending - blending
   * @param texture - textura
   * @param offRep - offset repeat
   * @returns novy config
   */
  private makeConfig(
    name: ParticleNames,
    blending: THREE.Blending,
    texture: THREE.Texture,
    offRep: number[],
    alpha: number | number[]
  ) {

    return {
      emitRate: 1,
      particleLife: 150,
      blending,
      name,
      basePosition: new THREE.Vector3(0, 0, 0),
      particleSpan: new THREE.Vector3(0.1, 0.1, 0.1),
      scale: [0.2, 0.3],
      mass: 0.05,
      alpha,
      gravitation: true,
      randomPower: [-0.01, 0.01],
      randomDrift: true,
      materialType: ParticleMaterialType.shader,
      velocity: new THREE.Vector3(0, 5, -10),
      vertexColors: false,
      rotation: 0,
      texture,
      particleAmountPerEmit: [5, 6],
      scene: player.playerObject,
      repeat: offRep,
      offset: offRep
    }

  }

  /**
   * Vyvorenie partiklov pre lyziarov
   */
  private makeSkiParticle(): void {

    const texture: THREE.Texture = game.getTexture(TexturesNames.smokeScreen)
    const textureSmall: THREE.Texture = game.getTexture(TexturesNames.smallSnow)

    const smokeScreenL = this.makeConfig(
      ParticleNames.smokeScreenL,
      THREE.AdditiveBlending,
      texture,
      [2, 2],
      0.01
    )

    const smokeScreenR = this.makeConfig(
      ParticleNames.smokeScreenR,
      THREE.AdditiveBlending,
      texture,
      [2, 2],
      0.01
    )

    const smallSnowL = this.makeConfig(
      ParticleNames.smallSnowL,
      THREE.NormalBlending,
      textureSmall,
      [1, 1],
      1
    )

    const smallSnowR = this.makeConfig(
      ParticleNames.smallSnowR,
      THREE.NormalBlending,
      textureSmall,
      [1, 1],
      1
    )

    particleManager.createEmitter(smokeScreenL)
    particleManager.createEmitter(smokeScreenR)

    particleManager.createEmitter(smallSnowL)
    particleManager.createEmitter(smallSnowR)

  }

  /**
   * Nahodny vyber stavu
   * @returns
   */
  private randomState(): SnowState {

    const r1 = Math.random()
    const isSnowing = r1 >= 0.4
    let idx = 0
    if (isSnowing) {

      const r2 = Math.random()
      idx = 1 + Math.floor(r2 * 3)

    }
    return this.snowingStates[idx]

  }

  /**
   * Taky ten random drift
   * @returns - random drift
   */
  private randomizedRandomDrift(): number[] {

    if (Math.random() >= 0.5) {

      return [-0.1, -0.2]

    } else {

      return [0.1, 0.2]

    }

  }

  /**
   * Metoda na cacheovanie partiklov
   */
  private createEmittersCache(): void {

    const snowEmitter = particleManager.getEmitter(ParticleNames.whiteSnow)
    const smokeScreenL = particleManager.getEmitter(ParticleNames.smokeScreenL)
    const smokeScreenR = particleManager.getEmitter(ParticleNames.smokeScreenR)
    const smallSnowL = particleManager.getEmitter(ParticleNames.smallSnowL)
    const smallSnowR = particleManager.getEmitter(ParticleNames.smallSnowR)

    this.emitters = {
      [ParticleNames.whiteSnow]: snowEmitter,
      [ParticleNames.smokeScreenL]: smokeScreenL,
      [ParticleNames.smokeScreenR]: smokeScreenR,
      [ParticleNames.smallSnowL]: smallSnowL,
      [ParticleNames.smallSnowR]: smallSnowR
    }

  }

  /**
   * Vrati emitter z cache
   * @param name - nazov emittera
   * @returns - emitter
   */
  private getEmitter(name: ParticleNames): ParticleEmitter | undefined {

    const emitter = this.emitters[name]
    if (!emitter) {

      console.warn('Nemame emitter')
      return undefined

    }
    return emitter

  }

  /**
   * Metoda na padanie snehu
   */
  private makeSnowFall(): void {

    const emitter = this.getEmitter(ParticleNames.whiteSnow)
    if (!emitter) return
    const position = cameraManager.getMainCamera().clone().position
    if (disciplinePhasesManager.actualPhase === DisciplinePhases.game) {

      const diff = player.playerObject.clone().position.sub(position)
      position.add(diff.multiplyScalar(gameMultiplicationScalar))

    }
    const defaultParticleSpan = particleManager.getDefaultParticleSettings().particleSpan ??
            new THREE.Vector3()
    position.y -= ((this.baseConfig.particleSpan?.y ?? defaultParticleSpan.y) / 2)
    position.z -= ((this.baseConfig.particleSpan?.z ?? defaultParticleSpan.z) / 2)
    position.x -= ((this.baseConfig.particleSpan?.x ?? defaultParticleSpan.x) / 2)
    emitter.setPosition(position)

  }

  /**
   * Sneh za lyziarom
   * TODO: Apply finite state machine
   */
  private makeSkiSnowMove(): void {

    const smokeL = this.getEmitter(ParticleNames.smokeScreenL)
    const smokeR = this.getEmitter(ParticleNames.smokeScreenR)
    const smallSnowL = this.getEmitter(ParticleNames.smallSnowL)
    const smallSnowR = this.getEmitter(ParticleNames.smallSnowR)

    if (!smokeL || !smokeR || !smallSnowL || !smallSnowR) return
    const playerVelocity = player.getSpeed()

    this.snowCallLogic(smokeL, SkiSide.left, playerVelocity, false)
    this.snowCallLogic(smokeR, SkiSide.right, playerVelocity, false)
    this.snowCallLogic(smallSnowL, SkiSide.left, playerVelocity, true)
    this.snowCallLogic(smallSnowR, SkiSide.right, playerVelocity, true)

  }

  /**
   * Call logika prvkov
   * @param smoke - Partikel
   * @param side - strana partikla
   * @param speed - rychlost
   * @param isSmallSnow - ci je sneh alebo smoke
   */
  private snowCallLogic(
    smoke: ParticleEmitter,
    side: SkiSide,
    speed: number,
    isSmallSnow: boolean
  ) {

    this.stopInAir(smoke)
    this.moveWithBone(smoke, side)
    this.startStopBySpeed(smoke, speed, isSmallSnow)
    this.changeStateOnFinish(smoke, side)
    this.changeStateUponInput(smoke, isSmallSnow)
    this.changeStateUponSpeed(smoke, speed)

  }

  /**
   * Zastavenie partiklov vo vzduchu
   * @param smoke - partikel emitter
   */
  private stopInAir(smoke: ParticleEmitter): void {

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.finish) return
    if (player.jumpStates.inAir) {

      this.stopSkiEmitR = true
      this.stopSkiEmitL = true
      smoke.stopEmitter()

    } else {

      this.stopSkiEmitR = false
      this.stopSkiEmitL = false
      smoke.startEmitter()

    }

  }

  /**
   * Pohyb partiklov s kostami
   * @param emitter - Particle emitter
   * @param side - Strana
   */
  private moveWithBone(emitter: ParticleEmitter, side: string) {

    const bone = player.playerObject.getObjectByName(`toe${side}`) as THREE.Bone
    const pos = bone.getWorldPosition(new THREE.Vector3())
    const posInPlayerObject = player.playerObject.worldToLocal(pos)
    posInPlayerObject.z -= 0.8
    emitter.setPosition(posInPlayerObject)

  }

  /**
   * Start stop partikla podla rychlsiti
   * @param smoke - emitter
   * @param speed - tychlost
   */
  private startStopBySpeed(smoke: ParticleEmitter, speed: number, isSmallSnow: boolean): void {

    const highVelocity = isSmallSnow ?
      skiParticleConfig.activationVelocitySnow :
      skiParticleConfig.activationVelocitySmoke

    if (speed > highVelocity &&
            !this.stopSkiEmitL &&
            !this.stopSkiEmitR
    ) {

      smoke.startEmitter()

    } else {

      smoke.stopEmitter()

    }

  }

  /**
   * Zmeni stav smokeu podla inputov
   * @param smoke - partikle emitter
   * @param isSnow - boolean
   */
  private changeStateUponInput(smoke: ParticleEmitter, isSnow: boolean): void {

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.finish) return

    const what = isSnow ? 'snow' : 'smoke'
    const whichState = inputsManager.moveDirectionLeft ? 'leftState' :
      inputsManager.moveDirectionRight ? 'rightState' : 'defaultState'

    smoke.setParticleSpan(skiParticleConfig[what][whichState].particleSpan)
    smoke.setScale(skiParticleConfig[what][whichState].particleSize)
    smoke.setRandomPower(skiParticleConfig[what][whichState].randomPower)

  }

  /**
   * Zmeni stav podla rychlosti
   * @param smoke - emittter
   * @param playerVelocity - rychlost
   */
  private changeStateUponSpeed(smoke: ParticleEmitter, playerVelocity: number): void {

    if (skiParticleConfig.maxParticleEmitSpeed - playerVelocity < 0) {

      smoke.setParticleAmountPerEmit(skiParticleConfig.maxParticleEmitOnSpeed)

    } else {

      const coef = playerVelocity / skiParticleConfig.maxParticleEmitSpeed
      let speed = coef * skiParticleConfig.maxParticleEmitOnSpeed
      speed = speed <= skiParticleConfig.lowestParticleEmitOnSpeed ?
        skiParticleConfig.lowestParticleEmitOnSpeed :
        Math.ceil(speed)
      smoke.setParticleAmountPerEmit(speed)

    }

  }

  /**
   * Zmeni stav vo finishy
   * @param smoke - emitter snehu
   */
  private changeStateOnFinish(smoke: ParticleEmitter, name: string): void {

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.finish) {

      const animationName = player.animationsManager.getActualAnimation()
      if (animationName !== PlayerAnimationsNames.stop) {

        if (name === SkiSide.left) {

          this.stopSkiEmitL = true

        } else {

          this.stopSkiEmitR = true

        }
        smoke.stopEmitter()
        return

      }

      const actualAnimationPercentage = player.animationsManager.getAnimationPercentageDone()

      smoke.setParticleAmountPerEmit(skiParticleConfig.finishPhase.particleAmount)
      if (actualAnimationPercentage < 50) {

        smoke.setVelocity(skiParticleConfig.finishPhase.velocity)
        smoke.setParticleSpan(skiParticleConfig.finishPhase.particleSpan)

      }

      if (actualAnimationPercentage >= 50) {

        if (name === 'L') {

          this.stopSkiEmitL = true

        } else {

          this.stopSkiEmitR = true

        }
        smoke.stopEmitter()

      }

    }

  }

  /**
   * Update metoda
   */
  public update(): void {

    this.makeSkiSnowMove()
    if (!this.isActive) return
    this.makeSnowFall()

  }

}
