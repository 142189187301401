import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { checkpointManager } from '../modes/training/CheckpointsManager'
import { ModelsNames } from '../types'
import { pathAssets } from '@/globals/globalvariables'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.skier]: {
    ext: 'glb',
    version: 25,
    genderActive: true,
    femaleVersion: 9,
    dir: modelsDir,
    mainMeshNames: [ModelsNames.skier]
  },
  [ModelsNames.hill]: {
    ext: 'glb',
    dir: modelsDir,
    version: 15,
    mainMeshNames: ['Physics_Track']
  },
  [ModelsNames.hillLong]: {
    ext: 'glb',
    dir: modelsDir,
    version: 21,
    mainMeshNames: ['Physics_Track']
  },
  [ModelsNames.checkpoint]: {
    ext: 'glb',
    dir: modelsDir,
    version: 8,
    mainMeshNames: [checkpointManager.CHECKPOINT_MESH_NAME],
    isTrainingModel: true
  },
  [ModelsNames.gates]: {
    ext: 'glb',
    dir: modelsDir,
    version: 4,
    useIfBatchingDisabled: true
  },
  [ModelsNames.gatesLong]: {
    ext: 'glb',
    dir: modelsDir,
    version: 17,
    useIfBatchingDisabled: true
  },
  [ModelsNames.animatedGateL]: {
    ext: 'glb',
    dir: modelsDir,
    version: 4
  },
  [ModelsNames.animatedGateR]: {
    ext: 'glb',
    dir: modelsDir,
    version: 4
  },
  /*
   * [ModelsNames.gatesPositionsLong]: {
   *   ext: 'glb',
   *   dir: modelsDir
   * },
   */
  /*
   * [ModelsNames.cameraBoxes]: {
   *   ext: 'glb',
   *   version: 2,
   *   dir: modelsDir
   * },
   */
  /*
   * [ModelsNames.cameraBoxesLong]: {
   *   ext: 'glb',
   *   dir: modelsDir
   * }
   */
}
