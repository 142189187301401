<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div v-show="!modalActive && !trainingState.firstTutorialMessage">
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <finish-top-box
          v-if="uiState.showFinishTopBox"
          :show-first-box="finishTopBoxState.showFirstBox"
          :personal-best="finishTopBoxState.personalBest"
          :new-personal-best="finishTopBoxState.newPersonalBest"
          :first-place="finishTopBoxState.firstPlace"
          :show-second-box="finishTopBoxState.showSecondBox"
          :time="finishTopBoxState.time"
          :position="finishTopBoxState.position"
        />
        <phase-start />
        <time-keeper-component />
        <traffic-component />
        <data-table />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
          class="pointer-events-auto"
        />
        <efficiency-component :is-mobile="isMobile" />
        <split-times-downhill v-if="uiState.showSplitTimes" />
        <downhill-times-component v-if="!uiState.isTraining" />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <info-keys v-if="!isMobile" />
        <black-overlay />
      </div>
      <fps-component />
      <tutorial-logic-component v-show="!modalActive" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-wsm="isAppWSM2021"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="blurState.isActive && !blurState.isToggle"
        :opacity="0.6"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
      <shiny-text-animated-component />
    </div>
    <loading />
  </div>
</template>

<script lang="ts">
// External Package codes

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager,
  displayManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
  BlackOverlay,
  loadingState,
  loadingCircleState
} from '@powerplay/core-minigames-ui'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import SplitTimesDownhill from '@/components/SplitTimesDownhill.vue'
import FinishTopBox from '@/components/FinishTopBox.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import EfficiencyComponent from '@/components/Inputs/EfficiencyComponent.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import DownhillTimesComponent from '@/components/DownhillTimesComponent.vue'
import TrafficComponent from '@/components/TrafficComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import InfoKeys from '@/components/InfoKeys.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import ShinyTextAnimatedComponent from '@/components/ShinyTextAnimatedComponent.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { defineComponent } from 'vue'
import {
  blurState,
  type BlurState,
  debugState,
  finishTopBoxState,
  uiState,
  trainingState,
  trainingResultsState
} from '@/stores'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    BlackOverlay,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    SplitTimesDownhill,
    TimeKeeperComponent,
    FinishTopBox,
    ErrorModal,
    DataTable,
    InfoComponent,
    EfficiencyComponent,
    SpeedMeterComponent,
    FpsComponent,
    TrafficComponent,
    DownhillTimesComponent,
    TrainingLayout,
    TrainingResults,
    TutorialLogicComponent,
    InfoKeys,
    LoadingCircleOverlay,
    TrainingOverride,
    ColorOverlayComponent,
    ShinyTextAnimatedComponent
  },
  data() {

    return {
      modalActive: false,
      isAppWSM2021: false,
      blurState: blurState(),
      debugState: debugState(),
      finishTopBoxState: finishTopBoxState(),
      uiState: uiState(),
      trainingState: trainingState(),
      trainingResultsState: trainingResultsState(),
    }

  },
  computed: {
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    showMenu(): boolean {

      return !this.showLoading() && !trainingResultsState().showResults

    },
    windowWidth() {

      return displayManager.width * displayManager.ratio

    },
    windowHeight() {

      return displayManager.height * displayManager.ratio

    },
    ratio() {

      return displayManager.ratio

    }
  },
  watch: {
    blurState: {
      handler(value: BlurState) {

        if (value.isActive) {

          document.getElementsByTagName('canvas')[0].classList.add('blur-class')

        } else {

          document.getElementsByTagName('canvas')[0].classList.remove('blur-class')

        }

        // osetrenie vypnutia menu pocas zobrazenej tabulky
        if (!value.isActive && value.isTable) {

          blurState().isActive = true

        }

      },
      deep: true
    },
  },
  created(): void {

    loadingState().$patch({
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppWSM2021 = modes.isAppWSM2021()
    initiateListeners(this)

  },
  methods: {
    showLoading(): boolean {

      return loadingState().showLoading

    },
    closeApp(): void {

      disciplinePhasesManager.prematureEnded = true
      loadingCircleState().isActive = true
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        inputsManager.actionInputsBlocked = true
        game.pauseGame()
        blurState().$patch( {
          isActive: true,
          isToggle: true
        })
        this.modalActive = true

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        blurState().$patch( {
          isActive: false,
          isToggle: false
        })
        game.resumeGame()

      }

    }
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
</style>
<style lang="less" scoped>

.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}
</style>
