<template>
  <section class="positioner-start-button">
    <section
      class="flex justify-between"
      :style="sectionStyle()"
    >
      <section
        :style="`transform: scale(${scaleCoef})`"
        class="relative"
        :class="[ gameSettingsState.isLeft ? 'left' : 'right' ]"
      >
        <arrow-animation
          :is-scaled="false"
          style="position: absolute; width: 580px; height: 460px; bottom: 0;"
          position="bottom"
        />
        <start-efficiency-bar
          :forced-size="100"
          :glow="true"
          :is-scaled="false"
          :is-left="gameSettingsState.isLeft"
        />
        <mobile-button
          :glow="true"
          :is-scaled="false"
          :type="'start-downhill'"
          @mousedown.stop=""
          @mouseup.stop=""
          @touchstart.stop="buttonClick()"
          @touchend.stop=""
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MobileButton,
  ArrowAnimation,
  WindowAspect,
  gameSettingsState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'
import StartEfficiencyBar from '@/components/Inputs/StartEfficiencyBar.vue'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialManager } from '@powerplay/core-minigames'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtonStart',
  components: {
    MobileButton,
    StartEfficiencyBar,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  data() {

    return {
      gameSettingsState: gameSettingsState(),
    }

  },
  methods: {
    buttonClick() {

      if (tutorialManager.getTypeWriting()) {

        tutorialCoreState().typeWriter = false
        tutorialManager.setTypeWriting(false)
        return

      }
      tutorialFlow.eventActionPressed()
      tutorialManager.nextSection()

    },
    sectionStyle() {

      if (!gameSettingsState().isLeft) return 'flex-flow: row-reverse;'

    }
  }
})
</script>

<style lang="less">
.positioner-start-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}
.power-mobile-button {
    pointer-events: auto;
}

.left {
  transform-origin: bottom left;
}

.right {
  transform-origin: bottom right;
}

</style>
