<template>
  <downhill-times
    v-if="gameplayTableState.showTables"
    :row-data="gameplayTableState.tableData"
    :player-data="gameplayTableState.playerData"
    :show-left-table="true"
    :show-right-table="false"
  />
</template>

<script lang="ts">
import { DownhillTimes } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { gameplayTableState } from '@/stores'

export default defineComponent({
  name: 'DownhillTimesComponent',
  components: {
    DownhillTimes
  },
  data() {

    return {
      gameplayTableState: gameplayTableState(),
    }

  }
})
</script>

<style lang="less" scoped>
</style>
