<template>
  <div>
    <power-stick
      v-if="gameSettingsState.controlsType === 0"
      only-horizontal
      :disabled="disabled"
      @position-x="changeMovement"
      @end-contact="endContact"
    />
    <movement-buttons
      v-if="gameSettingsState.controlsType === 1"
      :disabled="disabled"
      :is-scaled="false"
      @position="changeMovement"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
  PowerStick,
  MovementButtons,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import {
  game,
  inputsManager
} from '@powerplay/core-minigames'
import { movementState } from '@/stores'

export default defineComponent({
  components: {
    PowerStick,
    MovementButtons
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {

    return {
      gameSettingsState: gameSettingsState(),
    }

  },
  methods: {
    endContact() {

      movementState().positionX = 0
      console.log('end contact')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log(`blocked ${ game.inputsManager.actionInputsBlocked}`)
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      console.log('change movement', this.disabled)

      if (this.disabled) return

      movementState().positionX = positionX

    }
  }
})

</script>
