<template>
  <section class="positioner-buttons-movement">
    <section>
      <movement-sides
        :glow="true"
        :left-style="{transformOrigin: 'bottom left'}"
        :right-style="{transformOrigin: 'bottom right'}"
      />
      <section
        class="tutorial-arrows-container"
      >
        <div
          class="arrow-container-left"
          style="position: absolute; left: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; right: 0; bottom: 0; transform-origin: bottom left"
            position="bottom"
          />
        </div>
        <div
          class="arrow-container-right"
          style="position: absolute; right: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; right: 0; bottom: 0; transform-origin: bottom right"
            position="bottom"
          />
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MovementSides,
  ArrowAnimation
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtonsMovement',
  components: {
    MovementSides,
    ArrowAnimation
  }
})
</script>

<style lang="less">
.positioner-buttons-movement {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.tutorial-arrows-container {
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .arrow-container-left {
        width: 580px;
        height: 100%;
        position: relative;
        transform-origin: bottom left;
    }
    .arrow-container-right {
        width: 580px;
        height: 100%;
        position: relative;
        transform-origin: bottom right;
    }
}

</style>
