import {
  CorePhases,
  corePhasesManager,
  CustomEvents,
  minigameConfig,
  modes,
  PhaseFinalResultsManager,
  playersManager,
  requestManager,
  settings,
  SettingsTypes
} from '@powerplay/core-minigames'
import {
  gameSettingsState,
  waitingState
} from '@powerplay/core-minigames-ui'
import {
  getActivePinia,
  type Store
} from 'pinia'

export interface FpsDataStore {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  /**
   * Upravenie defaultnych dat (-1) co pridu zo servera v turnaji
   */
  public editTournamentDefaultValuesFromServer(): void {

    if (!modes.isTournament()) return

    // musime si este zamenit -1 za DNS
    for (let i = 0; i <= playersManager.players.length - 1; i += 1) {

      const resultArr = playersManager.players[i].resultsArr
      if (resultArr && resultArr[0] && resultArr[0].main === -1) {

        resultArr[0].main = minigameConfig.dnsValueAscending

      }

    }

  }

  /**
   * Porobenie veci, ked dobehnu vsetky potrebne requesty
   */
  public afterAllRequestsDone(): void {

    const isWaiting = requestManager.isFirstTrainingTutorial()
    const trainingButtonsDisabled = requestManager.isFirstTrainingTutorial()
    waitingState().$patch({
      isWaiting,
      trainingButtonsDisabled
    })

    // v tutoriali hned presmerujeme
    if (modes.isTutorial()) {

      window.dispatchEvent(new CustomEvent(CustomEvents.finalRedirectDone))
      requestManager.redirect()

    }

    const finalPhase = corePhasesManager.getPhase(CorePhases.finalResults) as PhaseFinalResultsManager
    if (finalPhase) finalPhase.enableSkip()

  }

  /**
   * Resetovanie pinie
   * @param specialExceptions - Specialne vynimky navyse
   */
  public resetPinia(specialExceptions: string[] = []): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']
    if (specialExceptions.length > 0) exceptions.push(...specialExceptions)

    // eslint-disable-next-line
    // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
