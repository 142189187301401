import { minigameConfig } from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TimerState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string,
  v2Expanded: boolean,
  showDiff: boolean,
  diffIndex: number,
  isV1: boolean,
  doTimeUpdate: boolean,
  bestTime: number,
  isFinish: boolean
}

const initialState = (): TimerState => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0',
  v2Expanded: false,
  showDiff: false,
  diffIndex: 0,
  isV1: true,
  doTimeUpdate: true,
  bestTime: minigameConfig.dnfValue,
  isFinish: false
})

export const timeState = defineStore('timeState', {
  state: initialState,
})
