<template>
  <time-keeper-expanded
    v-if="uiState.showTimeKeeper"
    style="top:0; right:0;"
    class="time-keeper"
    :show-diff="showDiff"
    :is-v1="timeState.isV1"
    :v2-expanded="timeState.v2Expanded"
    :player1="player1"
    :player2="player2"
  />
</template>

<script lang="ts">

import {
  TimeKeeperExpanded,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import {
  minigameConfig,
  playersManager,
  timeManager
} from '@powerplay/core-minigames'
import { defineComponent } from 'vue'
import {
  splitTimeState,
  timeState,
  type TimerState,
  uiState,
  type UiState
} from '@/stores'

interface DataInterface {
  time: string
  player1: PlayerInterface
  player2: PlayerInterface
  frameAfterPauseTime: boolean,
  timeState: TimerState,
  uiState: UiState
}

interface PlayerInterface {
  name?: string
  countryString?: string
  time?: string
  country?: string,
  position?: string,
  diff?: string,
  diffColor?: string
}

export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeperExpanded
  },
  mixins: [WindowAspect],
  data(): DataInterface {

    return {
      time: '',
      player1: {
        name: '',
        countryString: '',
        country: ''
      },
      player2: {
        name: '',
        countryString: '',
        country: ''
      },
      frameAfterPauseTime: true,
      timeState: timeState(),
      uiState: uiState(),
    }

  },
  computed: {
    showDiff() {

      if (timeState().bestTime === minigameConfig.dnfValue) return false
      if (splitTimeState().noSplitTimes) return false
      return timeState().showDiff

    }
  },
  watch: {
    timeState: {
      handler() {

        this.getPlayer1()
        this.getPlayer2()

      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    getPlayerPosition() {

      if (timeState().isFinish) {

        return String(playersManager.getPlayerActualPosition())

      }
      return ''

    },
    getPlayer1() {

      if (timeState().doTimeUpdate || this.frameAfterPauseTime) {

        this.time = timeState().timeWithPenalty

        if (!timeState().doTimeUpdate) {

          this.frameAfterPauseTime = false

          this.time = timeState().time ?
            Number(timeState().time).toFixed(2) :
            this.time

          this.time = timeManager.getTimeInFormatFromSeconds(Number(this.time))

        } else {

          this.frameAfterPauseTime = true
          this.time = this.time.slice(0, -1)

        }

      }

      const diff = splitTimeState().splitTimes?.[timeState().diffIndex]?.text
      const diffColor = splitTimeState().splitTimes?.[timeState().diffIndex]?.color

      this.player1 = {
        name: playersManager.players?.[0]?.name ?? '',
        country: playersManager.players?.[0]?.country.toLowerCase() ?? '',
        countryString: playersManager.players?.[0]?.countryString ?? '',
        position: this.getPlayerPosition(),
        time: diff === '' ? '' : this.time,
        diff,
        diffColor
      }

    },
    getPlayer2() {

      if (!timeState().v2Expanded) {

        this.player2 = {
          name: '',
          countryString: '',
          country: ''
        }
        const bestTime = timeState().bestTime
        if (bestTime && bestTime !== minigameConfig.dnfValue) {

          this.player2.time = timeManager.getTimeInFormatFromSeconds(timeState().bestTime)

        }

        if (splitTimeState().noSplitTimes) this.player2.time = ''

      } else if (this.player2.name === '') {

        const player2 = playersManager.getBestResultPlayerInfo()

        this.player2 = {
          name: player2?.name ?? '',
          countryString: player2?.countryString ?? '',
          country: player2?.country.toLowerCase() ?? ''
        }

        if (player2?.finalResult && player2?.finalResult !== minigameConfig.dnfValue) {

          this.player2.time = timeManager.getTimeInFormatFromSeconds(player2.finalResult)

        }

      }

    },
  }

})

</script>

<style lang="less" scoped>
.time-keeper {
    position: absolute;
    top: 17%;
    right: 1%;
}
</style>
