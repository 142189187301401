import { defineStore } from 'pinia'

export interface MovementState {
  positionX: number
}

const initialState = (): MovementState => ({
  positionX: 0
})

export const movementState = defineStore('movementState', {
  state: initialState,
})
