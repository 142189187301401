<template>
  <split-times
    v-if="showSplitTimes"
    class="split-times"
    :split-times="splitTimeState.splitTimes"
    :penalty="penalty"
  />
</template>

<script lang="ts">

import {
  SplitTimes,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { modes } from '@powerplay/core-minigames'
import { defineComponent } from 'vue'
import {
  splitTimeState,
  type SplitTimeState,
  timeState
} from '@/stores'

export default defineComponent({
  name: 'MiddletimesDownhill',
  components: {
    SplitTimes
  },
  mixins: [WindowAspect],
  data() {

    return {
      splitTimeState: splitTimeState(),
    }

  },
  computed: {
    showSplitTimes() {

      return !modes.isTutorial()

    },
    penalty() {

      return timeState().penaltySeconds > 0 ? String(timeState().penaltySeconds) : ''

    }
  },
  watch: {
    splitTimeState: {
      handler(value: SplitTimeState) {

        console.log('Realny medzicas', value.actualTime)

      }
    }
  }
})

</script>

<style scoped lang="less">
.split-times {
  position: absolute;
  transform-origin: top left;
  left: 1%;
  top: 17%;
}
</style>
