import {
  CANNON,
  timeManager,
  audioManager,
  CustomEvents,
  gsap,
  playersManager,
  corePhasesManager,
  CorePhases,
  TimesTypes,
  fpsManager,
  modes,
  type TournamentDataFromResultsRequest,
  type PlayerInfo
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  AudioNames,
  DisciplinePhases,
  TutorialEventType
} from '../types'
import { StartPhaseManager } from './StartPhaseManager'
import { FinishPhaseManager } from './FinishPhaseManager'
import { player } from '../player'
import { gameConfig } from '../config'
import { GamePhaseManager } from './GamePhaseManager'
import {
  speedmeterState,
  tableState,
  trainingResultsState
} from '@/stores'
import { gatesManager } from '../GatesManager'
import { endManager } from '../EndManager'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { waitingState } from '@powerplay/core-minigames-ui'
import { stateManager } from '../StateManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase: DisciplinePhases = DisciplinePhases.preStart

  /** pole vytvorenych faze managerov */
  public phaseManagers: DisciplinePhaseManager[] = []

  /** pocet framov ktore ubehli pri skatovani */
  public framesSkating = 0

  /** distance between start and end */
  public distanceToEnd = 0

  /** tween na nastartovanie fazoveho managera */
  public startDisciplineTween!: gsap.core.Tween

  /** tween na zobrazenie rychlosti */
  public speedmeterTween!: gsap.core.Tween

  /** posledna pozicia hraca */
  public lastPosition!: CANNON.Vec3

  /** momentalna rychlost v m/s */
  public actualSpeed!: number

  /** ci treba aktualizovat momentalnu rychlost */
  public actualSpeedNeedsUpdate = false

  /** ci mame zobrazit momentalnu rychlost */
  public actualSpeedShouldBeDisplayed = false

  /** Flag na zistenie ci je nastavena */
  public tournamentDataSet = false

  /** kolko framov bezi faza */
  private frames = 0

  /** ci bola hra ukoncena predcasne */
  public prematureEnded = false

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()

  }

  /**
   * Nastavenie hodnoty distanceToEnd
   */
  public setDistanceToEnd(): void {

    this.distanceToEnd = player.startPosition.distanceTo(gameConfig.maxAudienceValPos) -
            gameConfig.maxAudienceOffsetFromFinish

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseManagers[DisciplinePhases.start] = new StartPhaseManager(() => {

      player.launchStartAnimation()
      this.startDisciplinePhase(DisciplinePhases.game)
      timeManager.setActive(TimesTypes.game, true)
      // setTimeout(() => {

      //     this.resetAttempt()

      // }, 15000)

    })

    this.phaseManagers[DisciplinePhases.game] = new GamePhaseManager(() => {

      console.log('koniec')
      // zatial iba docasne
      player.physicsBody.type = CANNON.BODY_TYPES.STATIC
      timeManager.setActive(TimesTypes.game, false)
      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseManagers[DisciplinePhases.finish] = new FinishPhaseManager(() => {

      this.actualPhase++
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))
        waitingState().isWaiting = true

      }

    })

    // TODO: toto este budeme musiet nejako vymysliet, kam s tym
    player.setCollisionEndCallback(() => {

      if (this.actualPhase === DisciplinePhases.game) {

        /*
         * console.log('bol pad...')
         * const finish = this.phaseManagers[DisciplinePhases.finish] as FinishPhaseManager
         * finish.fall = true
         */

        // this.phaseManagers[DisciplinePhases.game].finishPhase()

        audioManager.play(AudioNames.crash)

        player.bounceAway()

        tutorialFlow.eventActionTrigger(TutorialEventType.fenceEvent)

      }

    })

  }

  /**
   * Vratenie konkretneho fazoveho menezera
   * @param phase - Faza
   * @returns Fazovy menezer
   */
  public getDisciplinePhaseManager(phase: DisciplinePhases): DisciplinePhaseManager {

    return this.phaseManagers[phase]

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase
    this.phaseManagers[phase].startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    this.frames++

    // TODO presunut do fazy ktora bude medzi Start a End
    if (player.isSkating) {

      if (this.framesSkating >= gameConfig.speedUpFrames) {

        player.endSkating()

      }
      this.framesSkating += 1

    }

    this.updateAudienceSound()

    this.phaseManagers[this.actualPhase]?.update()

    if (this.actualSpeedNeedsUpdate) this.setActualSpeed(player.physicsBody.velocity)
    this.lastPosition = player.physicsBody.position.clone()
    if (this.actualSpeedShouldBeDisplayed && !this.actualSpeedNeedsUpdate) {

      this.displayActualSpeed()

    }

  }

  /**
   * nastavime aktualnu rychlost
   */
  public setActualSpeed(velocity: CANNON.Vec3): void {

    this.actualSpeed = Math.sqrt((velocity.x ** 2) + (velocity.y ** 2) + (velocity.z ** 2))
    this.actualSpeedNeedsUpdate = false

    if (this.actualSpeed > endManager.maxSpeedLog) endManager.maxSpeedLog = this.actualSpeed

  }

  /**
   * zobrazime aktualnu rychlost
   */
  public showActualSpeed(): void {

    this.actualSpeedNeedsUpdate = true
    this.actualSpeedShouldBeDisplayed = true

  }

  private displayActualSpeed(): void {

    if (this.actualPhase !== DisciplinePhases.game || !this.actualSpeed) return
    if (this.speedmeterTween) this.speedmeterTween.progress(1)

    speedmeterState().$patch({
      visible: true,
      speed: Number(this.actualSpeed.toFixed(2))
    })

    this.speedmeterTween = gsap.to({}, {
      duration: 2,
      onComplete: () => {

        speedmeterState().visible = false

      }
    })
    this.actualSpeedShouldBeDisplayed = false

  }

  /**
   * zmen hlasitost divakov podla vzdialenosti
   */
  public updateAudienceSound(): void {

    const phases = [DisciplinePhases.preStart]

    if (
      phases.includes(disciplinePhasesManager.actualPhase) ||
            !corePhasesManager.isActivePhaseByType(CorePhases.discipline)) {

      return

    }

    let actualDistance = player.physicsBody.position.distanceTo(gameConfig.maxAudienceValPos)

    // dame offset pred cielom, aby to nebolo az v cieli
    actualDistance -= gameConfig.maxAudienceOffsetFromFinish
    if (actualDistance < 0) actualDistance = 0
    const percentDistance = 1 - actualDistance / this.distanceToEnd

    const volume = gameConfig.minAudienceSound +
            ((gameConfig.maxAudienceSound - gameConfig.minAudienceSound) * percentDistance)

    audioManager.changeAudioVolume(AudioNames.audienceNoise, volume)

  }

  /**
   * Predcasne ukoncenie discipliny
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)
    // musime vymazat vsetky aktivne fazy
    this.actualPhase = DisciplinePhases.end

    player.physicsBody.type = CANNON.BODY_TYPES.STATIC
    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount

    playersManager.setPlayerResultsDNF()

    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    stateManager.resetPinia(modes.isTournament() ? ['waitingState', 'blurState', 'tableState'] : [])

    waitingState().isWaiting = true
    tableState().$patch({
      showTable: true,
      activeState: false,
      dataTable: [],
      isStartList: false,
    })
    trainingResultsState().isDisabledPlayAgain = true

    // stopneme vsetky animacne callbacky
    player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: 0.2,
      onComplete: () => {

        this.startDisciplinePhase(DisciplinePhases.start)

      }
    })

  }

  /**
   * resetovanie hry
   */
  public resetAttempt(): void {

    gatesManager.reset()
    player.reset()

    this.actualPhase = 0
    this.framesSkating = 0
    this.distanceToEnd = 0
    this.actualSpeedNeedsUpdate = false
    this.actualSpeedShouldBeDisplayed = false
    this.startDisciplinePhase(DisciplinePhases.start)

  }

  /**
   * Nastavenie dat o hracoch
   * @param dataCallback - funkcia na ukladanie
   */
  public setOpponentsForFinishTable(dataCallback: TournamentDataFromResultsRequest): void {

    const newPlayersData: PlayerInfo[] = []
    let index = 0
    dataCallback.players.forEach((playerInfo) => {

      playerInfo.attribute = {
        base: 0,
        total: 0
      }

      if (playerInfo.uuid === player.uuid) playerInfo.playable = true

      // musime zarucit, ze hrac bude aj tu prvy
      if (!playerInfo.playable) index += 1
      newPlayersData[playerInfo.playable ? 0 : index] = playerInfo

    })

    playersManager.players = newPlayersData

    // musime este upravit data pre hraca, kedze sa vyssim priradenim dali na DNF
    playersManager.players[0].resultsArr = playersManager.players[0].resultsArrOriginal

    this.tournamentDataSet = true

    waitingState().isWaiting = false
    tableState().activeState = true

    window.dispatchEvent(new CustomEvent(CustomEvents.startFinalStandingsPhase))

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.framesSkating = 0
    this.createAllPhases()
    this.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
