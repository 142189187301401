import { defineStore } from 'pinia'

export interface FinishTopBoxState {
  showFirstBox: boolean
  showSecondBox: boolean
  firstPlace: boolean
  personalBest: boolean
  newPersonalBest: boolean
  time: string
  position: number
}

const initialState = (): FinishTopBoxState => ({
  showFirstBox: false,
  showSecondBox: false,
  firstPlace: false,
  personalBest: false,
  newPersonalBest: false,
  time: '',
  position: 0
})

export const finishTopBoxState = defineStore('finishTopBoxState', {
  state: initialState,
})
