<template>
  <div class="positioner">
    <tutorial-keyboard-keys
      v-if="showArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
      />
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpacePressed"
      type="space-pressed"
      :text="$t('webInfoTuck')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space-pressed"
        letter="hold"
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialKeyboardKeys,
  TutorialKeyboardKey,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import {
  modes,
  ControlsTypes
} from '@powerplay/core-minigames'
import { defineComponent } from 'vue'
import {
  actionButtonState,
  inputsState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  mixins: [WindowAspect],
  computed: {
    isControlsTypeAutoTuck() {

      if (modes.isTutorial()) return true
      return gameSettingsState().controlsType === ControlsTypes.autoTuck ||
        Number(import.meta.env.VITE_APP_AUTOTUCK_FORCED) === 1

    },
    showSpace() {

      return startPhaseState().showBar

    },
    showSpacePressed() {

      return inputsState().isVisible &&
        actionButtonState().showMovementControl &&
        !this.isControlsTypeAutoTuck &&
        !inputsState().disabled

    },
    showArrows() {

      return actionButtonState().showMovementControl && !inputsState().disabled && inputsState().isVisible

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }
    }
</style>
