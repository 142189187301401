import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'tutorialTask1-2',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },
  {
    text: 'tutorialText1-3',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },
  {
    text: 'instructionsDisciplineTraining1Cell3Text1',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },
  {
    text: 'instructionsDiscipline1Cell3Text1',
    replace: [
      {
        original: '[2to4]',
        new: '2'
      }
    ]
  },

]
