import { defineStore } from 'pinia'
import type { GameplayTableRow } from '@/app/types'

export interface GameplayTableState {
  showTables: boolean,
  showLeftTable: boolean,
  showRightTable: boolean,
  tableData: GameplayTableRow[],
  playerData: GameplayTableRow
}

const initialState = (): GameplayTableState => ({
  showTables: false,
  showLeftTable: true,
  showRightTable: true,
  tableData: [{
    position: 0,
    country: '',
    countryString: '',
    player: {
      name: '',
      isPlayer: true
    },
    time: '',
    timeDiff: '',
    isBonus: false
  }],
  playerData: {
    position: 0,
    country: '',
    countryString: '',
    player: {
      name: '',
      isPlayer: true
    },
    time: '',
    timeDiff: '',
    isBonus: false
  }
})

export const gameplayTableState = defineStore('gameplayTableState', {
  state: initialState,
})
